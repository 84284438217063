<template>
  <div
    :class="{ 'opacity-40': eventPackage == 'free' }"
    class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow"
  >
    <div class="flex items-center justify-between">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{ content?.title }}
      </h2>
      <button
        v-if="eventStore.isOwner"
        @click="toggleEditMode"
        class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800 mr-2"
      >
        Edit
      </button>
    </div>
    <hr class="mb-3" />
    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{ content?.name }} : </span>
        <span class="text-gray-600">{{ address?.name || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800"
          >{{ content?.phoneNumber }} :
        </span>
        <span class="text-gray-600">{{ address?.mobile || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800"
          >{{ content?.address }} :
        </span>
        <span class="text-gray-600">{{ fullAddress || "-" }}</span>
      </p>
    </div>

    <!-- <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800"
          >{{ content?.province }} :
        </span>
        <span class="text-gray-600">{{ address?.province || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{ content?.city }} : </span>
        <span class="text-gray-600">{{ address?.city || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800"
          >{{ content?.district }} :
        </span>
        <span class="text-gray-600">{{ address?.district || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800"
          >{{ content?.postalCode }} :
        </span>
        <span class="text-gray-600">{{ address?.postal_code || "-" }}</span>
      </p>
    </div> -->

    <!-- <div class="px-3 mb-2">
      <label class="text-gray-600  mt-2"
        >{{content?.produceBookDateLabel}} : {{ address.produce_book_date || "-" }}</label
      >
    </div>

    <div class="py-4 text-xs text-gray-700  pl-4 italic">
      <p class="">{{content?.eventDate}}  :{{ eventStore.event?.information.date }}</p>
      <p class="">{{content?.produceDate}} : {{ address.produce_book_date }}</p>
      <p class="">{{content?.estimateDate}}  : {{ estimateDate }}</p>
    </div> -->
  </div>
</template>

<script setup>
import dayjs from "dayjs";
const eventStore = useEventStore();
const props = defineProps({
  content: { type: Object, default: {} },
  isEdit: { type: Boolean, required: true },
  eventPackage: { type: String, required: true },
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};

const address = computed(
  () =>
    eventStore.event?.shipping || {
      name: "",
      mobile: "",
      address: "",
      province: "",
      city: "",
      district: "",
      postalCode: "",
      produce_book_date: "",
    }
);

const estimateDate = computed(() => {
  if (address.produce_book_date) {
    return dayjs(address.produce_book_date).add(5, "day").format("YYYY-MM-DD");
  }
  return "";
});

const fullAddress = computed(() => {
  return `${address.value.address || ""} ${address.value.city || ""} ${address.value.district || ""} ${address.value.province || ""} ${address.value.postal_code || ""}`.trim();
});
</script>
