<template>
  <form @submit.prevent="onSave">
    <div
      class="max-w py-3 mb-3 mx-2 bg-white border border-gray-200 rounded-lg shadow"
    >
      <!-- Title for the Card -->
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{ content?.title }}
      </h2>
      <hr class="mb-3" />
      <div class="px-3 mb-2">
        <label class="text-gray-600">{{ content?.name }}</label>
        <input
          class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
          type="text"
          v-model="address.name"
          required
        />
      </div>

      <div class="px-3 mb-2">
        <label class="text-gray-600 mt-2">{{ content?.phoneNumber }}</label>
        <input
          class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
          type="text"
          v-model="address.mobile"
          required
        />
      </div>

     

      <div class="grid grid-cols-2">
        <div class="px-3 mb-2">
          <label class="text-gray-600 mt-2">{{ content?.province }}</label>
          <select
            v-model="address.province"
            @change="addressComp.setSelectedProvince($event.target.value)"
            class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
            required
          >
            <option value="" disabled>Select a province</option>
            <option
              v-for="province in addressComp.provinces.value"
              :key="province"
              :value="province"
            >
              {{ province }}
            </option>
          </select>
        </div>

        <div class="px-3 mb-2">
          <label class="text-gray-600 mt-2">{{ content?.city }}</label>
          <select
            v-model="address.city"
            @change="addressComp.setSelectedCity($event.target.value)"
            class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
            required
          >
            <option value="" disabled>Select a city</option>
            <option
              v-for="city in addressComp.cityNames.value"
              :key="city"
              :value="city"
            >
              {{ city }}
            </option>
          </select>
        </div>
      </div>

      <div class="grid grid-cols-2">
        <div class="px-3 mb-2">
          <label class="text-gray-600 mt-2">{{ content?.district }}</label>
          <select
            v-model="address.district"
            @change="addressComp.setSelectedDistrict($event.target.value)"
            class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
            required
          >
            <option value="" disabled>Select a district</option>
            <option
              v-for="district in addressComp.districtNames.value"
              :key="district"
              :value="district"
            >
              {{ district }}
            </option>
          </select>
        </div>

        <div class="px-3 mb-2">
          <label class="text-gray-600 mt-2">{{ content?.postalCode }}</label>
          <select
            v-model="address.postal_code"
            class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
            required
          >
            <option value="" disabled>Select a postal code</option>
            <option
              v-for="postalCode in addressComp.postalCodes.value"
              :key="postalCode"
              :value="postalCode"
            >
              {{ postalCode }}
            </option>
          </select>
        </div>
      </div>

      <div class="px-3 mb-2">
        <label class="text-gray-600 mt-2">{{ content?.additionalAddress }}</label>
        <textarea
          v-model="address.address"
          id="message"
          rows="4"
          class="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow"
          required
        ></textarea>
      </div>

      <!-- <div class="px-3 mb-2">
        <label for="shipping_date" class="block text-gray-700    mb-2"
          >{{content?.produceBookDate}}</label
        >
        <input
          type="date"
          id="shipping_date"
          name="shipping_date"
          format="yyyy-MM-dd"
          :min="minDate"
          :max="maxDate"
          class="shadow appearance-none border  bg-white   rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
          required
          v-model="address.produce_book_date"
          :disabled="!eventStore.isOwner"
        />
        <div class="py-4 text-xs text-gray-700  pl-4 italic">

          <p class="">{{content?.eventDate}}  :{{ eventStore.event?.information.date }}</p>
          <p class="">{{content?.produceDate}} : {{ address.produce_book_date }}</p>
          <p class="">{{content?.estimateDate}}  : {{ estimateDate }}</p>
        </div>
      </div> -->

      <div class="flex justify-end mt-1 px-3">
        <button
          type="submit"
          class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100"
        >
          Save
        </button>
        <button
          type="button"
          class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100"
          @click="toggleEditMode"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import dayjs from "dayjs";
import _ from "lodash";
const eventStore = useEventStore();
const emit = defineEmits(["onEdit"]);
const props = defineProps({
  content: { type: Object, default: {} },
  isEdit: { type: Boolean, required: true },
  callback: { type: Function, required: true },
});

const holderAddress = computed(() => _.cloneDeep(eventStore.event?.shipping));
const addressComp = useAddress(
  holderAddress.value?.province || "",
  holderAddress.value?.city || "",
  holderAddress.value?.district || ""
);

const minDate = ref(eventStore.event?.information?.date);
const maxDate = ref(dayjs(minDate.value).add(14, "day").format("YYYY-MM-DD"));
const address = reactive(
  holderAddress.value || {
    name: "",
    mobile: "",
    address: "",
    produce_book_date: "",
    province: "",
    city: "",
    district: "",
    postal_code: "",
  }
);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
  address.name = holderAddress.value?.name || "";
  address.mobile = holderAddress.value?.mobile || "";
  address.address = holderAddress.value?.address || "";
  address.produce_book_date = holderAddress.value?.produce_book_date || "";
  address.province = holderAddress.value?.province || address.province;
  address.city = holderAddress.value?.city || address.city;
  address.district = holderAddress.value?.district || address.district;
  address.postal_code = holderAddress.value?.postal_code || address.postal_code;
};

const onSave = () => {
  props.callback(address);
};
</script>
