<template>
  <AppBlockScreen
    :isShow="eventPackage == 'free' ? true : false"
    @submit="saveEventInfo"
    class="mx-2 mb-3 rounded-lg shadow"
  >
    <template #description>
      <p class="mt-3">ปรับแต่งข้อมูล</p>
      <p class="">
        สำหรับแพคเกจ
        <span class="underline" @click="$router.push('/package/checkout')"
          >Pro</span
        >
        เท่านั้น
      </p>
    </template>

    <!-- Edit Mode -->
    <event-shipping-edit
      v-if="isEdit"
      :content="content"
      @onEdit="handleChangeEditMode"
      :callback="onSave"
      :isEdit="isEdit"
    />

    <!-- Show Mode -->
    <event-shipping-show
      v-else
      :content="content"
      @onEdit="handleChangeEditMode"
      :isEdit="isEdit"
      :eventPackage="eventPackage"
    />
  </AppBlockScreen>
</template>

<script setup>
import { useEventStore } from "@/stores/event";
const useEvent = useEventStore();
const props = defineProps(["content"]);
const eventPackage = computed(() => useEvent.event && useEvent.event.package);
const isEdit = ref(false);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = (address) => {
  isEdit.value = false;
  useEvent.saveShipping({
    name: address.name,
    mobile: address.mobile,
    address: address.address,
    province: address.province,
    city: address.city,
    district: address.district,
    postal_code: address.postal_code,
    produce_book_date: address.produce_book_date,
  });
};
</script>

<style></style>
