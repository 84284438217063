// ---- welcome page ------
export const welcomeTemplates = [
  {
    templateId: "welcome_1",
    themeId: "default_welcome_1",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/b458b8da-229b-4bf9-d6ee-c12ef03eaa00/original",
    fonts: {
      welcome: "Callem",
      thankyou: ""
    },
    colors: {
      main: "#0855CB",
      background: "#f1f5f9",
    }
  },
  {
    templateId: "welcome_2",
    themeId: "default_welcome_2",
    // image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/ab67bc04-a2c1-40a7-a3b2-adbef7a88b00/original",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e5407314-bab1-4e78-8002-1e4619273000/original",
    fonts: {
      welcome: "Parisienne",
      thankyou: ""
    },
    colors: {
      main: "#733d21",
      background: "#f7f4f2",
    }
  },
  {
    templateId: "welcome_3",
    themeId: "default_welcome_3",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/001d5f10-1038-4388-6f28-6317127a3900/original",
    fonts: {
      welcome: "Cinzel",
      thankyou: ""
    },
    colors: {
      main: "#49503F",
      background: "#eceeeb",
    }
  },
  {
    templateId: "welcome_4",
    themeId: "default_welcome_4",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e8d71420-b566-4762-d1d4-d168c5188700/original",
    fonts: {
      welcome: "Sweet Belly Script",
      thankyou: ""
    },
    colors: {
      main: "#AE6344",
      background: "#f2ecea",
    }
  },
  {
    templateId: "welcome_5",
    themeId: "default_welcome_5",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/245fb3a0-b58e-46f4-5dd3-c52bc8094400/original",
    fonts: {
      welcome: "Cinzel",
      thankyou: ""
    },
    colors: {
      main: "#46596c",
      background: "#f4f7f9",
    }
  },
  {
    templateId: "welcome_6",
    themeId: "default_welcome_6",
    // image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/55324265-e454-433b-9193-3d901a7eae00/original",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/ac5f4137-5147-420f-0d32-8178205a8500/original",
    fonts: {
      welcome: "Playfair Display",
      thankyou: ""
    },
    colors: {
      main: "#6f7649",
      background: "#f6f7f2",
    }
  },
  {
    templateId: "welcome_7",
    themeId: "default_welcome_7",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/2323e101-29b6-4b57-8232-d7cc9cbf3000/original",
    fonts: {
      welcome: "Callem",
      thankyou: ""
    },
    colors: {
      main: "#733d21",
      background: "#fffaf8",
    }
  },
  {
    templateId: "welcome_8",
    themeId: "default_welcome_8",
    image: "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/2f996a4b-64c9-4798-ef8e-601080076b00/original",
    fonts: {
      welcome: "Callem",
      thankyou: ""
    },
    colors: {
      main: "#c89100",
      background: "#fcfaf3",
    }
  },
]

export const presetColors = [
   // ----- default colors ------
  {
    default: true,
    themeId: "default_welcome_1",
    main: "#0855CB",
    backgrounds: ["#f1f5f9", "#e0e8f1", "#d7e3f0", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_2",
    main: "#733d21",
    backgrounds: ["#f7f4f2", "#f8eee8", "#f6e1d3", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_3",
    main: "#49503F",
    backgrounds: ["#eceeeb", "#d6dbd4", "#c1c5bf", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_4",
    main: "#AE6344",
    backgrounds: ["#f2ecea", "#f3e5e0", "#edd4cb", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_5",
    main: "#46596c",
    backgrounds: ["#f4f7f9", "#e0eaf0", "#d7eaf5", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_6",
    main: "#6f7649",
    backgrounds: ["#f6f7f2", "#e8e9e1", "#d1d3c4", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_7",
    main: "#733d21",
    backgrounds: ["#fffaf8", "#f6e7e1", "#f1d7cd", "#f6f6f6"],
  },
  {
    default: true,
    themeId: "default_welcome_8",
    main: "#c89100",
    backgrounds: ["#fcfaf3", "#f3efe1", "#e8e2cc", "#f6f6f6"],
  },

  // ----- preset colors ------
  {
    themeId: "red",
    main: "#db4437",
    backgrounds: ["#fae3e1", "#f6d0cd", "#f2beb9", "#f6f6f6"],
  },
  {
    themeId: "pink",
    main: "#ffa6a9",
    backgrounds: ["#ffedee", "#ffe4e5", "#ffdbdd", "#f6f6f6"],
  },
  {
    themeId: "red_orange",
    main: "#ff5722",
    backgrounds: ["#ffe6de", "#ffd5c8", "#ffc4b2", "#f6f6f6"],
  },
  {
    themeId: "orange",
    main: "#ff9800",
    backgrounds: ["#fff0d9", "#ffe5bf", "#ffdba6", "#f6f6f6"],
  },
  {
    themeId: "purple",
    main: "#673ab7",
    backgrounds: ["#f0ebf8", "#e1d8f1", "#d1c4e9", "#f6f6f6"],
  },
  {
    themeId: "indigo",
    main: "#3f51b5",
    backgrounds: ["#eceef8", "#d9dcf0", "#c5cbe9", "#f6f6f6"],
  },
  {
    themeId: "blue",
    main: "#4285f4",
    backgrounds: ["#e3edfd", "#d0e1fc", "#bdd4fb", "#f6f6f6"],
  },
  {
    themeId: "light_blue",
    main: "#03a9f4",
    backgrounds: ["#d9f2fd", "#c0eafc", "#a7e1fb", "#f6f6f6"],
  },
  {
    themeId: "blue_green",
    main: "#009688",
    backgrounds: ["#d9efed", "#bfe5e1", "#a6dad5", "#f6f6f6"],
  },
  {
    themeId: "green",
    main: "#4caf50",
    backgrounds: ["#e4f3e5", "#d2ebd3", "#c0e3c2", "#f6f6f6"],
  },
  {
    themeId: "lime",
    main: "#65a30d",
    backgrounds: ["#f3f7eb", "#ecfccb", "#d9f99d", "#f6f6f6"],
  },
  {
    themeId: "teal",
    main: "#0d9488",
    backgrounds: ["#f0fdfa", "#ccfbf1", "#b4f9eb", "#f6f6f6"],
  },
  {
    themeId: "neutral",
    main: "#525252",
    backgrounds: ["#fafafa", "#f5f5f5", "#e5e5e5", "#f6f6f6"],
  },
  {
    themeId: "gray",
    main: "#4b5563",
    backgrounds: ["#f9fafb", "#f3f4f6", "#e5e7eb", "#f6f6f6"],
  },
  {
    themeId: "zinc",
    main: "#71717a",
    backgrounds: ["#fafafa", "#f4f4f5", "#e4e4e7", "#f6f6f6"],
  },
  {
    themeId: "stone",
    main: "#78716c",
    backgrounds: ["#fafaf9", "#f5f5f4", "#e7e5e4", "#f6f6f6"],
  },

]

