import { i18n } from "~/plugins/i18n";
import { useAppStateStore } from '~/stores/appState'
import WishComp_info from '~/pages/v2/wish/main.vue'
import WishComp_share from '~/pages/v2/wish/qrcodeAndLink.vue'
import WishComp_templates from '~/pages/v2/wish/templates.vue'
import WishComp_howtouse from '~/pages/v2/wish/howToUse.vue'
import WishComp_faqs from '~/pages/v2/wish/faqs.vue'


export const useContentWish = (page: string) => {
  const appStateStore = useAppStateStore();

  const lang = computed(() => i18n.global.locale.value)
  const isThaiLang = computed(() => lang.value === 'th')

  // ------------------- Wish -------------------

  const contentWishHome = computed(() => {
    return {
      title: isThaiLang.value ? "เมนูหนังสืออวยพร" : "Menu of Photobook",
      menus: [
        {
          name: isThaiLang.value ? "ข้อมูลงาน" : "Event Information",
          id: "EventInfo",
          icon: "fa-solid fa-calendar-lines-pen",
          callback: () => {
            appStateStore.setMenuSelected("EventInfo");
          },
        },
        {
          name: isThaiLang.value ? "เลือกเทมเพลส" : "Select Templates",
          id: "EventTemplates",
          icon: "fa-solid fa-image-polaroid-user",
          callback: () => {
            appStateStore.setMenuSelected("EventTemplates");
          },
        },
        {
          name: isThaiLang.value ? "Qr Code / แชร์ " : "Qr Code / Share",
          id: "EventQrcodeAndLink",
          icon: " fa-regular fa-qrcode",
          callback: () => {
            appStateStore.setMenuSelected("EventQrcodeAndLink");
          },
        },
        // {
        //   name: isThaiLang.value ? "ขั้นตอนการใช้งาน" : "How to Use",
        //   id: "EventHowToUse",
        //   icon: "fa-duotone fa-solid fa-book",
        //   callback: () => {
        //     appStateStore.setMenuSelected("EventHowToUse");
        //   },
        // },
        // {
        //   name: isThaiLang.value ? "คำถามที่พบบ่อย" : "FAQs",
        //   id: "EventFaqs",
        //   icon: "fa-solid fa-message-question",
        //   callback: () => {
        //     appStateStore.setMenuSelected("EventFaqs");
        //   },
        // }, 
      ],
      components: {
        'EventInfo': WishComp_info,
        'EventQrcodeAndLink': WishComp_share,
        'EventTemplates': WishComp_templates,
        'EventHowToUse': WishComp_howtouse,
        'EventFaqs': WishComp_faqs,
      }

    }
  })

  const contentWishInfo = computed(() => {
    return {
      title: isThaiLang.value ? "ข้อมูลงาน" : "Event Info",
      inValidText: isThaiLang.value ? "กรุณากรอกข้อมูลเป็นภาษาอังกฤษเท่านั้น" : "Please fill in English only",
      info: {
        title: isThaiLang.value ? "ข้อมูลงาน" : "Event Information",
        active: isThaiLang.value ? "เปิดใช้ระบบ" : "Active",
        location: isThaiLang.value ? "ชื่อสถานที่" : "Location Name ",
        locationEdit: isThaiLang.value ? "ชื่อสถานที่ (ภาษาอังกฤษเท่านั้น)" : "Location Name (English only)",
        province: isThaiLang.value ? "จังหวัด" : "Province",
        eventDate: isThaiLang.value ? "จัดงานวันที่" : "Event Date",
        scheduledEvent: isThaiLang.value ? "งานเลี้ยงจัดช่วงเวลา" : "Scheduled Event",
        morning: isThaiLang.value ? "งานเช้า" : "Morning",
        evening: isThaiLang.value ? "งานเย็น" : "Evening",

        title2: isThaiLang.value ? "แอปเขียนคำอวยพร (สำหรับแขก)" : "Wish app information (For guest)",
        lang: isThaiLang.value ? "ภาษาเริ่มต้นในหน้าแรก" : "Language (Wish app)",
        showRandomWish: isThaiLang.value ? "แนะนำข้อความคำอวยพร" : "Show Random button (Wish app)",
        helperRandomText: isThaiLang.value ? "หมายเหตุ: ปุ่มแนะนำคำอวยพรสำหรับแขก ในกรณีที่คิดคำอวยพรไม่ออก" : "The button suggests wish text for guests",
      },
      bride: {
        title: isThaiLang.value ? "ข้อมูลเจ้าสาว" : "Bride's Information",
        firstName: isThaiLang.value ? "ชื่อ" : "First Name",
        lastName: isThaiLang.value ? "นามสกุล" : "Last Name",
        nickName: isThaiLang.value ? "ชื่อเล่น" : "Nick Name"
      },
      groom: {
        title: isThaiLang.value ? "ข้อมูลเจ้าบ่าว" : "Bride's Information",
        firstName: isThaiLang.value ? "ชื่อ" : "First Name",
        lastName: isThaiLang.value ? "นามสกุล" : "Last Name",
        nickName: isThaiLang.value ? "ชื่อเล่น" : "Nick Name"
      },

      customInfo: {
        title: isThaiLang.value ? "ปรับแต่งข้อมูล (Optional)" : "Custom Information (Optional)",
        customHashtag: isThaiLang.value ? "กำหนด Hashtag เอง" : "Custom Hashtag",
        customHashtagEdit: isThaiLang.value ? "กำหนด Hashtag เอง (english only)" : "Custom Hashtag (ภาษาอังกฤษเท่านั้น)",
        subTitle: isThaiLang.value ? "เวลาที่ใช้ในการเล่น Real-time Slides" : "Timer for Play Real-time Slides",
        photo: isThaiLang.value ? "รูปพรีเว็ดดิ้ง" : "Photos",
        wish: isThaiLang.value ? "รูปคำอวยพร" : "Wish",
        unit: isThaiLang.value ? "วินาที" : "seconds",
        photoEdited: isThaiLang.value ? "รูปพรีเว็ดดิ้ง / รูป" : "Photos / 1 photo",
        wishEdited: isThaiLang.value ? "รูปคำอวยพร / รูป" : "wishes / 1 wish",
      },

      shipping: {
        title: isThaiLang.value ? "ที่อยู่สำหรับจัดส่ง" : "Shipping Address",
        name: isThaiLang.value ? "ชื่อผู้รับ" : "Name",
        phoneNumber: isThaiLang.value ? "เบอร์ปลายทาง" : "Phone Number",
        address: isThaiLang.value ? "ที่อยู่" : "Address",
        additionalAddress: isThaiLang.value ? "บ้านเลขที่/หมู่บ้าน" : "Address",
        province: isThaiLang.value ? "จังหวัด" : "Province",
        city: isThaiLang.value ? "เขต/อำเภอ" : "City",
        district: isThaiLang.value ? "แขวง/ตำบล" : "District",
        postalCode: isThaiLang.value ? "รหัสไปรษณีย์" : "Zip Code",
        produceBookDate: isThaiLang.value ? "วันในการผลิต PhotoBook (คลิกที่ปฏิทิน) " : "Produce book date (click on calendar icon)",
        produceBookDateLabel: isThaiLang.value ? "วันในการผลิต PhotoBook " : "Produce book date",
        eventDate: isThaiLang.value ? "งานแต่งจัดวันที่" : "Event Date",
        produceDate: isThaiLang.value ? "ผลิตหนังสือวันที่" : "Produce book at",
        estimateDate: isThaiLang.value ? "จะได้รับหนังสือประมาณวันที่" : "Estimated duration",
      },

      produceBook: {
        title: isThaiLang.value ? "รูปแบบการผลิตหนังสือ" : "Produce Photobook",
        list: [
          {
            id: 'design-by-photowish',
            type: isThaiLang.value ? 'ทีมงาน Photowish จะจัดวาง Layout ของรูป Prewedding ให้ (เฉพาะรูปที่อัพโหลดในระบบ)' : `Photowish will arrange the layout for the prewedding photos (only for the photos uploaded to the system).`
          },
          {
            id: 'design-by-photowish-googledrive',
            type: isThaiLang.value ? 'ทีมงาน Photowish จะจัดวาง Layout ของรูป Prewedding และรูปจากวันงาน (ส่งลิ้งค์ URL ผ่านระบบ หรือ ทางไลน์ Photowish)' : `Photowish will arrange the layout for the prewedding photos and the photos from the wedding day (sent to us via Google Drive at the input field below or Line).`,
          },
          {
            id: 'design-by-user',
            type: isThaiLang.value ? 'เจ้าบ่าว/เจ้าสาว ออกแบบ Photobook เองผ่าน canva.com' : `The bride and groom will design the photobook themselves.`,
            link: " https://youtu.be/H6B4m1YdAb8?si=d0wNTHnNwd6U8Z_f"
            // link: "https://youtu.be/P2hT34cUZ5g?si=BJWyfQedYrPMYSsk"
          },
        ],
        selectedType: isThaiLang.value ? 'การจัดเรียงเลย์เอาท์รูปภาพ' : 'Layout of photobook',
        guidelineText: isThaiLang.value ? 'ไกด์ไลน์ Canva' : 'Guideline Canva',
        guidelineLink: "https://youtu.be/H6B4m1YdAb8?si=d0wNTHnNwd6U8Z_f",
        remark: isThaiLang.value ? 'หมายเหตุ: ก่อนวันงาน 3-7 วันจะมีเจ้าหน้าที่โทรไปซัพพอร์ตและอธิบายขั้นตอนอีกครั้ง โดยสามารถเปลี่ยนแปลงหรือแก้ไขได้ในภายหลัง' : 'Remark: 3-7 days before the event, a staff member will call to provide support and explain the process again. Changes can be made after the call if needed.',
        url: isThaiLang.value ? 'ลิ้งค์สำหรับ GoogleDrive หรือ Canva.com' : `Link for Google Drive or Canva.com"`,

        sort: {
          type: isThaiLang.value ? 'รูปแบบการจัดเรียงคำอวยพร' : 'Sort wishes type',
          remark: isThaiLang.value ? 'หากไม่ได้กำหนดไว้จะเป็นการเรียงตาม Timeline (ก่อนไปหลัง)' : 'If not specified, it will sort by timeline (earliest to latest).',
          list: [
            {
              id: 'sort-by-timeline',
              type: isThaiLang.value ? "เรียงตาม Timeline (ก่อนไปหลัง)" : "Sort by Timeline (earliest to latest)."
            },
            {
              id: 'sort-by-random',
              type: isThaiLang.value ? "เรียงแบบสุ่ม" : "Sort by Random"
            },
            {
              id: 'sort-by-custom',
              type: isThaiLang.value ? "เรียงตามที่กำหนดเอง " : "Sort by Custom"
            },

          ]
        },
      },

      font: {
        title: isThaiLang.value ? "ฟ้อนท์ (Optional)" : "Font (Optional)",
        title2: isThaiLang.value ? "ตัวอย่าง" : "Example Welcome Page",
        font: isThaiLang.value ? "ฟ้อนท์ที่กำลังใช้งาน" : "Font",
        fontEdit: isThaiLang.value ? "เลือกฟ้อนท์ (เปลี่ยนเฉพาะชื่อเจ้าบ่าวและเจ้าสาว)" : "Select Font (change bride and groom's name only)",
      },

      customTheme: {
        title: isThaiLang.value ? "หน้าปกและธีมสี (Optional)" : "First page and theme (Optional)",
        title2: isThaiLang.value ? "ตัวอย่าง" : "Example Thankyou Page",
        template: {
          title: isThaiLang.value ? "ดีไซน์หน้าปกที่ใช้" : "Design cover first page",
          titleEdit: isThaiLang.value ? "เลือกดีไซน์หน้าปก (แอปเขียนคำอวยพร)" : "Select design cover first page (Wish app)",
        },
        theme: {
          title: isThaiLang.value ? "ธีมสีที่ใช้" : "Theme Colors",
          titleEdit: isThaiLang.value ? "เลือกธีมสีที่ใช้ภายใน (แอปเขียนคำอวยพร)" : "Select theme color (Wish app)",
          main: isThaiLang.value ? "สีหลัก" : "Main",
          background: isThaiLang.value ? "สีพื้นหลังด้านใน" : "Background inside",
          backgroundEdit: isThaiLang.value ? "สีพื้นหลังด้านใน (แอปเขียนคำอวยพร)" : "Background inside (Wish app)",
        },
        font: {
          title: isThaiLang.value ? "ฟ้อนท์ที่ใช้" : "Font",
          titleEdit: isThaiLang.value ? "เลือกฟ้อนท์ (เปลี่ยนเฉพาะชื่อเจ้าบ่าวและเจ้าสาว)" : "Select Font (change bride and groom's name only)",
        },
      },

      customThankyou: {
        title: isThaiLang.value ? "หน้าขอบคุณ (Optional)" : "Thankyou page (Optional)",
        title2: isThaiLang.value ? "ตัวอย่าง" : "Example Thankyou Page",
        customTitleEdit: isThaiLang.value ? "หัวข้อคำขอบคุณ" : "Title",
        customDescriptionEdit: isThaiLang.value ? "ข้อความที่จะแสดง" : "Description",
        customTextLabel: isThaiLang.value ? "กำหนดข้อความด้วยตัวเอง" : "Custom message"
      },
    }
  })

  const contentWishShare = computed(() => {
    return {
      title: isThaiLang.value ? "แชร์ข้อมูลงาน" : "Choose data sharing",
      list: [
        {
          id: "qrcode",
          title: isThaiLang.value ? "รูป QR-CODE  (สำหรับให้แขกอวยพร)" : "QR-CODE (For guests)",
          description: isThaiLang.value ? "กดที่รูปค้างไว้ แล้วเลือก save เพื่อดาวน์โหลดลงเครื่อง" : "Press on QR code image and select `save` for download",
          label: isThaiLang.value ? "สร้าง QR CODE" : "Generate QR CODE",
          type: "image",
        },
        {
          id: "link-guest",
          title: isThaiLang.value ? "Link (สำหรับให้แขกอวยพร)" : "Link (For guests)",
          description: isThaiLang.value ? "กด Copy แล้วส่งให้แขกได้เลย" : "click `Copy Link` button and sent to your guests",
          label: isThaiLang.value ? "คัดลอกลิ้งค์" : "Copy Link",
          type: "link",
        },

        // {
        //   id: "share-info",
        //   title: isThaiLang.value ? "แชร์ข้อมูลงาน" : "Share Event ",
        //   description: isThaiLang.value ? "กด Copy แล้วส่งให้เจ้าบ่าว หรือเจ้าสาว เพื่อเข้าถึง Event นี้" :  "click `Copy Link` button and sent to bride or groom for access this event",
        //   label:isThaiLang.value ? "คัดลอกลิ้งค์" : "Copy Link",
        //   type:"link",
        // },
        {
          id: 'pair-event',
          title: isThaiLang.value ? "เชื่อมต่อ QR Code กับ Account" : "Pair QR Code with the account",
          description: isThaiLang.value ? "เชื่อมต่อ Qr Code กับ account นี้ " : "Pair QR Code with the account",
          label: isThaiLang.value ? "ทำการเชื่อมต่อ" : "Pairing",
          type: 'page'
        }

      ]
    }
  })

  const contentWishTemplate = computed(() => {
    return {
      title: isThaiLang.value ? 'เลือกเทมเพลตที่ต้องการ' : 'Select Templates',
      remark: isThaiLang.value ? 'คำแนะนำ: ระบบเขียนคำอวยพร จะทำการสุ่มมา 3 เทมเพลส ให้แขกเลือกใช้ในการเขียนคำอวยพร' : 'Advice: The wish app will randomly offer 3 templates or guests to choose from.'
    }
  })

  const contentWishHowToUse = computed(() => {
    return {
      title: isThaiLang.value ? 'ขั้นตอนการใช้งาน' : 'How To Use',
      list: [
        {
          title: 'step 1',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 2',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 3',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 4',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
      ]
    }
  })

  const contentWishFaqs = computed(() => {
    return {
      title: isThaiLang.value ? 'คำถามที่พบบ่อย' : 'FAQs',
      list: [
        {
          question: "PhotoWish คืออะไร",
          answer: "PhotoWish คือการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และบ่าวสาวจะได้รับรูปเล่มคำอวยพรด้วยรูป (WishBook) จัดส่งให้ในภายหลัง",
          open: false,
        },
        {
          question: "แขกจะเขียนอวยพรได้อย่างไร",
          answer: "สำหรับแพ็คเกจโปร ทางเรา QR-CODE และ Stand QR-Code(A4) จัดส่งไปให้ล่วงหน้าในวันงานเจ้าบ่าวเจ้าสาวเพียงตั้ง QR-CODE ไว้ที่จุดลงทะเบียน(จุดรับของชำร่วย/ใส่ซอง) แขกแค่สแกนและอวยพร แทนการเขียนด้วยสมุด (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น)",
          open: false,
        },
        {
          question: "แขกเลือกรูปที่จะอวยพรได้จากไหน",
          answer: "หลังจาก Scan แล้วจะเข้าสู่ระบบ, เลือกรูปจาก Gallery ในมือถือของตัวเองได้เลย และเขียนคำอวยพรได้ทันที (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น) ",
          open: false,
        },
        {
          question: "การต่อภาพ Slides และ รูปคำอวยพรแบบRealtime จะทำอย่างไร",
          answer: "เจ้าบ่าวเจ้าสาวสามารถแชร์ลิ้งค์ ให้กับทางโรมแรมเพื่อนำไปเปิดบนจอในห้องงานเลี้ยงได้เลย (การเปิด Slide Online เหมือนเปิดลิ้งค์ Youtube ทั่วไป) ",
          open: false,
        },
        {
          question: "ฉันสามารถแก้ไขและจัดเรียงรูปถ่ายในโฟโต้บุ๊คได้ไหม",
          answer: "หลังจากจบงาน ทางเราผลิต WishBook และจัดส่งให้ตามวันที่เจ้าบ่าวเจ้าสาวกำหนดไว้ในระบบ, กรณีส่ง messenger เฉพาะกรุงเทพมหานครและปริมณฑลจะได้รับหนังสือภายในวันนั้น (มีค่าจัดส่งเพิ่มเติม), กรณีส่ง ไปรษณีย์ จะได้รับภายใน 2-3 วัน(ขึ้นกับบริษัทขนส่ง) ",
          open: false,
        },
        {
          question: "ในวันแต่งจะมีเจ้าหน้าที่มาที่หน้างานหรือไม่",
          answer: "PhotoWish เป็นระบบออนไลน์ จะไม่มีเจ้าหน้าที่ไปอยู่ที่งานแต่ง  (แต่จะมีเจ้าหน้าที่ IT support ช่วยเหลือทางออนไลน์ในกรณีติดปัญหาการใช้งาน โทร: 063-048-4510)",
          open: false,

        },
        {
          question: "แขกในงานจะได้รับรูปปริ้นหรือไม่",
          answer: "ทางแขกในงานจะสามารถดาวน์โหลดรูปคำอวยพร (wish image) ได้ทันทีในระบบอวยพร, แต่จะไม่มีรูปปริ้นออกมาเป็นกระดาษจริงภายในงาน",
          open: false,

        },
        {
          question: "PhotoWish แตกต่างจาก PhotoBooth อย่างไร",
          answer: "PhotoWish จะเป็นการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และจัดทำเป็นรูปเล่มคำอวยพรจัดส่งให้ในวันถัดไป, PhotoBooth จะเป็นการถ่ายรูป 3 ท่าทาง ให้กับแขกที่มาในงานที่บูธถ่ายรูป และจะได้รับรูปถ่ายเป็นที่ระลึก",
          open: false,
        },


        // Add more questions as needed
      ]
    }
  })


  // ---- computed to get current content by page -----
  const currentContent = computed(() => {
    let content: any = {}
    switch (page) {
      // ------ wish -------
      case 'wish-home':
        content = contentWishHome.value
        break;

      case 'wish-info':
        content = contentWishInfo.value
        break;

      case 'wish-share':
        content = contentWishShare.value
        break;

      case 'wish-template':
        content = contentWishTemplate.value
        break;

      case 'wish-howToUse':
        content = contentWishHowToUse.value
        break;

      case 'wish-faqs':
        content = contentWishFaqs.value
        break;

      default:
        break;
    }

    return content
  })

  return {
    content: currentContent, // The localized content
  }
}
